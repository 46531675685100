import { addDoc, collection } from 'firebase/firestore'
import React, { useState } from 'react'
import { AiOutlineSend } from 'react-icons/ai'
import { hero} from '../assets/assets'
import db from "../firebase.js"

const Hero = ({registerRef}) => {

  const onRegister = () =>{
    registerRef.current.scrollIntoView({behavior: "smooth"})
  }

  return (
    <div className='w-full bg-white py-12'>
        <div className='md:max-w-[1240px] m-auto grid lg:grid-cols-2 max-w-[600px] px-4 md:px-4'>
            <div className='flex flex-col justify-start gap-4'>
                <h3 className='py-4 text-2xl text-[#60B6BF] font-medium'>POWER UP YOUR TECH CAREER</h3>
                <h1 className='md:leading-[72px] py-2  md:text-6xl font-semibold text-5xl'>Get Access to <br></br><span className='text-[#60B6BF]'>Tech</span> & <span className='text-[#60B6BF]'>Career Insights</span>, Unlock Your Success in the <span className='text-[#60B6BF]'>Digital Age</span></h1>
                <p className='py-2 text-lg text-[#372800]'>Level up your tech career today with industry insights from your Favourite, Career Guidance JP Gandhi and <br></br> Sivakumar Kulasekaran (Senior Cloud Architect)  – join our newsletter now!</p>
                <div className='lg:max-w-[700px] w-full flex flex-col items-center lg:flex-row lg:justify-between'>
    <button 

    onClick={onRegister}
    type="button" className="flex-col  text-white bg-[#60B6BF] w-full lg:w-auto lg:px-8 hover:scale-105 transition-all hover:bg-[#498c93] focus:ring-4 focus:outline-none focus:ring-[#498c93] font-medium rounded-lg text-sm px-5 py-2.5 text-center flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 mb-3 lg:mb-0">
        Register →
    </button>
</div>

            </div>
            <img className='lg:order-last order-first flex m-auto justify-end ' src={hero} alt="newsletter" />
        </div>

    </div>
  )
}

export default Hero