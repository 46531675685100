import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD9pPcDQl5hMxBhnSQMe3kCeLpMf3bNpJQ",
  authDomain: "jpnews-d8968.firebaseapp.com",
  projectId: "jpnews-d8968",
  storageBucket: "jpnews-d8968.appspot.com",
  messagingSenderId: "29118249961",
  appId: "1:29118249961:web:9fbe4fa9a009f68eb1a978"
};

// Initialize Firebase

 const app = initializeApp(firebaseConfig);
 const db = getFirestore(app);

export const auth = getAuth();
export default db;