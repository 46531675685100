import React from 'react'
import {SlSocialLinkedin,SlSocialFacebook, SlSocialInstagram, SlSocialTwitter,SlSocialYoutube} from 'react-icons/sl'
import { forwardRef } from 'react';

const Footer = forwardRef((props, ref) => {
  return (
    <div className='mx-auto py-16 md:px-32 px-4 grid lg:grid-cols-2 gap-8 text-gray-300 bg-[#1f2130] w-full ' ref={ref} id='contact'>

        <div className=''>
        <h1 className='w-full text-3xl font-bold text-[#60B6BF] py-2'>Career Guidance <br></br>Jayaprakash Gandhi</h1>
        <hr className='py-1 w-[100px]'></hr>
        <p className='py-4 text-gray-300'>
        <span className=' text-[#60B6BF]'>Address:</span><br></br>
            Career Guidance,<br></br>
            12/164, Amar Prakash Building,<br></br>
            Sathyanarayana Road,<br></br>
            Swarnapuri, Salem - 636004.<br></br>
</p>

<p>
<span className=' text-[#60B6BF]'>Phone:</span> +91-427-2449482<br></br>

<span className=' text-[#60B6BF]'>Mobile:</span> +91 99948 97500<br></br>

<span className=' text-[#60B6BF]'>Email:</span> nikkitagandhi@gmail.com<br></br>

<span className=' text-[#60B6BF]'>Email:</span> careerguidancejpgandhi@gmail.com<br></br>
        </p>
        </div>
        <div className='flex flex-col justify-start items-start '>
            <p className=''>{String.fromCharCode(169)} careerguidancejpgandhi.com</p>
            <p className='text-gray-600'>Made with ❤️ by <a className='opacity-50 hover:opacity-100 text-white' href='https://linkedin.com/in/justinbenito' target="">Justin Benito</a></p>
            <div className=' flex justify-start md:space-x-10 space-x-5'>
            <button className='bg-[#60B6BF] rounded-md font-medium my-6  text-black hover:scale-105 duration-150 ease-in-out' onClick={()=> window.location.href='https://www.facebook.com/careerguidancejpgandhi/'}>
                <SlSocialFacebook size={20} className="m-2 items-center justify-between"/>
            </button>
            <button className='bg-[#60B6BF] rounded-md font-medium my-6  text-black hover:scale-105 duration-150 ease-in-out' onClick={()=> window.location.href='https://www.instagram.com/careerguidancejpgandhi/'}>
                <SlSocialInstagram size={20} className="m-2 items-center justify-between"/>
            </button>
            <button className='bg-[#60B6BF] rounded-md font-medium my-6  text-black hover:scale-105 duration-150 ease-in-out' onClick={()=> window.location.href='https://twitter.com/jpgandhi'}>
                <SlSocialTwitter size={20} className="m-2 items-center justify-between"/>
            </button>
            <button className='bg-[#60B6BF] rounded-md font-medium my-6  text-black hover:scale-105 duration-150 ease-in-out' onClick={()=> window.location.href='https://www.linkedin.com/in/jayaprakash-gandhi-478b7919/'}>
                <SlSocialLinkedin size={20} className="m-2 items-center justify-between"/>
            </button>
            <button className='bg-[#60B6BF] rounded-md font-medium my-6  text-black hover:scale-105 duration-150 ease-in-out' onClick={()=> window.location.href='https://www.youtube.com/@CareerGuidanceJPGandhi'}>
                <SlSocialYoutube size={20} className="m-2 items-center justify-between"/>
            </button>
            </div>
        </div>

    </div>
  )
}
)

export default Footer 