import React from 'react';
import { useSpring, animated } from 'react-spring';
import { useInView } from 'react-intersection-observer';
import { forwardRef } from 'react';

export const About = forwardRef((props, ref) => {
  const [inViewRef, inView] = useInView({
    triggerOnce: true, // Trigger the animation once when it enters the viewport
    threshold: 0.5, // Adjust this threshold as needed
  });

  const animationProps = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? 'translateY(0px)' : 'translateY(100px)',
  });

  return (
    <animated.div
      style={animationProps}
      className='w-full my-32'
      id='about'
      ref={(node) => {
        inViewRef(node);
      }}
    >
      <div className='max-w-[1240px] mx-auto ' ref={ref} >
        <div className='text-center'>
          <h2 className='text-5xl font-bold'>
            Trusted by Students around the world
          </h2>
          <p className='text-2xl py-6 text-gray-500 p-2'>
            JP Gandhi: Your trusted career mentor, guiding you towards success
            in the ever-evolving tech industry.
          </p>
        </div>

        <div className='grid md:grid-cols-4 gap-1 px-2 text-center'>
          <div className='border py-8 rounded-xl shadow-xl mx-2 my-2 hover:bg-gray-100 transition duration-200 ease-in-out'>
            <p className='text-6xl font-bold text-[#60B6BF]'>21+</p>
            <p className='text-gray-400 mt-2'>YEARS OF EXPERIENCE</p>
          </div>
          <div className='border py-8 rounded-xl shadow-xl mx-2 my-2 hover:bg-gray-100 transition duration-200 ease-in-out'>
            <p className='text-6xl font-bold text-[#60B6BF]'>7750+</p>
            <p className='text-gray-400 mt-2'>PROGRAMS</p>
          </div>
          <div className='border py-8 rounded-xl shadow-xl mx-2 my-2 hover:bg-gray-100 transition duration-200 ease-in-out'>
            <p className='text-6xl font-bold text-[#60B6BF]'>2Lakh</p>
            <p className='text-gray-400 mt-2'>COUNSELLED</p>
          </div>
          <div className='border py-8 rounded-xl shadow-xl mx-2 my-2 hover:bg-gray-100 transition duration-200 ease-in-out'>
            <p className='text-6xl font-bold text-[#60B6BF]'>2000+</p>
            <p className='text-gray-400 mt-2'>PLACEMENT TRAINING</p>
          </div>
        </div>
      </div>
    </animated.div>
  );
});

export default About;
