import React, { useState, forwardRef } from 'react';
import { logo } from '../assets/assets';
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai';


const Navbar = ({refs}) => {
  const [click, onClick] = useState(false);

  const handleClick = () => onClick(!click);

  const onAbout = () => {

    if (refs.about.current) {
      refs.about.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const onRegister = () => {
    if (refs.register.current) {
      refs.register.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  


  return (
    <div className='w-full h-[80px] bg-white border-b'>
      <div className='md:max-w-[1240px] max-w-[600px] m-auto w-full h-full flex justify-between items-center md:px-2 px-4'>
        <img src={logo} className='h-[25px]' />
        <div className='hidden md:flex '>
          <ul className='flex gap-4'>
            <li className='hover:opacity-50 transition ease-in-out transition-300'>Home</li>
            <li><button onClick={onAbout} className='hover:opacity-50 transition ease-in-out transition-300'>About</button></li>
            {/* <li><button onClick={onContact} className='hover:opacity-50 transition ease-in-out transition-300'>Contact</button></li> */}
            <li><button onClick={onRegister} className='hover:opacity-50 transition ease-in-out transition-300'>Register</button></li>
          </ul>
        </div>
        <div className='md:hidden' onClick={handleClick}>
          {click ? <AiOutlineClose size={25} /> : <AiOutlineMenu size={25} />}
        </div>
      </div>
      <div className={click ? 'absolute z-10 p-4 bg-white w-full px-4 md:hidden' : 'hidden'}>
        <ul>
          <li className='p-4 hover:bg-yellow-50' >Home</li>
          <li className='p-4 hover:bg-yellow-50' onClick={onAbout}>About</li>
          <li className='p-4 hover:bg-yellow-50' onClick={onRegister}>Register</li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
