import React,{useState} from 'react'
import About from './components/about'
import { Hero,Navbar } from './components'

import { useRef } from 'react';
import {useSpring, animated} from 'react-spring'
import Footer from './components/contact';

import Suggestions from './components/suggestions';
import Forms from './components/forms';
import  { Toaster } from 'react-hot-toast';

const App = () => {
const [fade, setFade]=useState( false)
const props=useSpring({
from: {opacity: 0},
to: {opacity: 1},
delay: 800,
onRest: ()=> setFade(!fade),

})


  const aboutRef = useRef(null);
  const contactRef=useRef(null);
  const registerRef=useRef(null);

  const result={
    about: aboutRef,
    contact: contactRef,
    register: registerRef
  }

  return (
    <animated.div className='' style={props}>
      <Toaster />
      <Navbar refs={result} />
      <Hero registerRef={registerRef}/>
      <About ref={aboutRef}/>
      <Forms ref={registerRef} />
      {/* <Suggestions /> */}
      <Footer ref={contactRef} />
    </animated.div>

  )
}

export default App