import { addDoc, collection } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { forwardRef } from 'react';
import db from "../firebase.js"
import { useInView } from 'react-intersection-observer';
import { animated, useSpring } from 'react-spring';

export const Forms = forwardRef((props, ref) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  let [number, setNumber] = useState('');
  const [designation, setDesignation] = useState('');
  const [location, setLocation] = useState('');



  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create an object with the form data
    const formData = {
      name,
      email,
      number,
      designation,
      location,
    };

    try {
      const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  
      if (!emailRegex.test(email)) {
        toast.error("Enter a Valid Email")
        throw("Enter a Valid Number")
      }
      number=number.replace(/\s/g, "")
      if(number.length!=10){
        toast.error("Enter a Valid Number")
        throw("Enter a Valid Number")
      }


      if(name==="" || email==="" || number==="" || designation==="" || location===""){
    
        throw("Error")
      }


      const docRef = await addDoc(collection(db, 'newusers'), formData);



      // Clear the form fields after successful submission
      setName('');
      setEmail('');
      setNumber('');
      setDesignation('');
      setLocation('');
      toast.success('Thanks for Registering');
    } catch (error) {

      toast.error('Error adding you, if this issue persist please reach out to info@innocareertech.com');
    }
  };

  // Use the useInView hook to detect when the form is in the viewport
  const [formRef, inView] = useInView({
    triggerOnce: true, // Trigger the animation once
  });

  // Define animations for form elements
  const nameAnimation = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? 'translateY(0px)' : 'translateY(100px)',
    config: { duration: 500 },
  });

  const emailAnimation = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? 'translateY(0px)' : 'translateY(100px)',
    config: { duration: 500 },
  });

  const numberAnimation = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? 'translateY(0px)' : 'translateY(100px)',
    config: { duration: 500 },
  });

  const designationAnimation = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? 'translateY(0px)' : 'translateY(100px)',
    config: { duration: 500 },
  });

  const locationAnimation = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? 'translateY(0px)' : 'translateY(100px)',
    config: { duration: 500 },
  });

  const buttonAnimation = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? 'scale(1)' : 'scale(0.8)',
    config: { duration: 500 },
  });

  return (
    <section className="bg-white dark:bg-gray-900" ref={ref} id="register">
      <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
        <div className="text-center">
          <h2 className="text-5xl font-bold">Register Now</h2>
          <p className="text-2xl py-6 text-gray-500 p-2">
            Register to get game-changing Career and Tech insights
          </p>
        </div>

        <form className="mt-8" ref={formRef}>
          <animated.div style={nameAnimation} className="relative z-0 w-full mb-6 group">
            <input
              value={name}
              onChange={(e) => setName(e.target.value)}
              type="text"
              name="floating_first_name"
              id="floating_first_name"
              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder=" "
              required
            />
            <label
              htmlFor="floating_first_name"
              className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
              First name
            </label>
          </animated.div>

          <animated.div style={emailAnimation} className="relative z-0 w-full mb-6 group">
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              name="floating_email"
              id="floating_email"
              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder=" "
              required
            />
            <label
              htmlFor="floating_email"
              className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
              Email address
            </label>
          </animated.div>

          <div className="grid md:grid-cols-2 md:gap-6">
            <animated.div style={numberAnimation} className="relative z-0 w-full mb-6 group">
              <input
                value={number}
                onChange={(e) => setNumber(e.target.value)}
                type="tel"
                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                name="floating_phone"
                id="floating_phone"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                required
              />
              <label
                htmlFor="floating_phone"
                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Phone number (+91)
              </label>
            </animated.div>

            <animated.div style={designationAnimation} className="relative z-0 w-full mb-6 group">
              <input
                value={designation}
                onChange={(e) => setDesignation(e.target.value)}
                type="text"
                name="floating_company"
                id="floating_company"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                required
              />
              <label
                htmlFor="floating_company"
                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Designation eg. Software Engineer
              </label>
            </animated.div>
          </div>

          <animated.div style={locationAnimation} className="relative z-0 w-full mb-6 group">
            <input
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              type="text"
              name="location"
              id="location"
              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder=" "
              required
            />
            <label
              htmlFor="location"
              className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
              Location eg. Chennai
            </label>
          </animated.div>

          <animated.button
            style={buttonAnimation}
            onClick={handleSubmit}
            type="submit"
            className="text-white bg-[#60B6BF] hover:bg-[#498c93] transition-colors focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto sm:flex sm:items-center px-5 py-2.5 sm:px-10 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 mx-auto"
          >
            Register
          </animated.button>
        </form>
      </div>
    </section>
  );
});

export default Forms;
